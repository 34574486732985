(function () {
  'use strict';

  /* @ngdoc object
   * @name statistics.teams
   * @description
   *
   */
  angular
    .module('neo.home.statistics.teams', [
      'ui.router'
    ]);
}());
